// Cold colors
$aqua: #7fdbff
$blue: #0074d9
$navy: #001f3f
$teal: #39cccc
$green: #2ecc40
$olive: #3d9970
$lime: #01ff70

// Warm colors
$yellow: #ffdc00
$orange: #ff851b
$red: #ff4136
$fuchsia: #f012be
$purple: #b10dc9
$maroon: #85144b

// Grayscale
$white: #ffffff
$silver: #dddddd
$gray: #aaaaaa
$black: #111111

$pagination-padding-x: 1.5vw
