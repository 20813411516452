@import './_variables'
@import "bootstrap"

$image-max-height: 20vw
$marker-width: 30px

img.book-image
  max-width: 100%

div.book-container
  margin-top: 1rem
  margin-bottom: 1rem

#page-image-container
  max-height: $image-max-height
  overflow-y: scroll
  border-radius: 20px
  margin-left: 1vw
  margin-right: 1vw
  padding: 0
  width: 97vw

iframe.editor_iframe
  height: 300px

iframe.editor_iframe_1
  height: 120px

#page_bottom_spacer
  height: $image-max-height

div.toolbar
  margin-top: 0.5rem
  margin-bottom: 0.5rem

#page-image
  width: 100%

#marker
  position: sticky
  width: 98%
  top: calc($image-max-height / 2) + 2.5vw
  opacity: 13%
  margin-left: 5px
  margin-right: 5px

.inline-control
  width: unset
  display: initial
  line-height: 1.3

#transcription-display
  white-space: pre-line
  font-size: 2rem
  margin: 2rem

.disabled-link
  color: gray
  pointer-events: none
  cursor: not-allowed
  text-decoration: none

#custom_review_editor
  height: 130px

.error-handling-loading
  position: fixed
  top: 10px

.horizontal-scroll
  white-space: nowrap
  overflow-y: hidden
  resize: none
  height: 2em

.transcription-line p.comment
  font-size: small
  color: blue
  font-style: italic

.transcription-line div.english
  font-size: large
  font-family: Arial, sans-serif
